import React from "react"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import bed from "../images/bed.svg"
import bath from "../images/bath.svg"
import area from "../images/area.svg"
const PostEntry = ({ post }) => {
  const { uri, title, featuredImage, CustomPost, excerpt } = post
  // console.log(CustomPost.price)
  return (
    <div className="col-md-4 col-xs-12">
      <article className="item">
        <div className="featured-image">
          <Link to={`${uri}`}>
            <div className="ov1"></div>
            <div className="ov2"></div>
            <div className="ov3"></div>
            <div className="ov4"></div>
            <GatsbyImage image={getImage(featuredImage.node.localFile)} alt={title} />
          </Link>
        </div>
        <div className="title">
        {/* <Link to={`${blogURI}/${uri}/`} title={title}> */}
          <Link to={`${uri}`} title={title}>
            <h3>{title}</h3>
          </Link>
        </div>
        <div className="excerpt">
          {(() => {
            if (CustomPost.address != null) {
              return (
                <div className="address">
                  <span>
                    <i className="fa fa-map-marker" aria-hidden="true"></i>
                    {CustomPost.address}
                  </span>
                </div>
              )
            }
            else{
              return(
                <><div dangerouslySetInnerHTML={{ __html: excerpt.replace(/<p class="link-more.*/, '') }} /></>
              )
            }
          })()}
          {(() => {
            if (CustomPost.price != null) {
              return (
                <div className="price">
                  <span>Giá: ~ {CustomPost.price}</span>
                </div>
              )
            }
          })()}
          
          <div className="d-flex justify-content-between">
            {(() => {
              if (CustomPost.bedroom != null) {
                return (
                  <div>
                    <span>
                      <img src={bed} alt="bed" />
                      {CustomPost.bedroom}
                    </span>
                  </div>
                )
              }
              
            })()}
            {(() => {
              if (CustomPost.wc != null) {
                return (
                  <div>
                    <span>
                      <img src={bath} alt="bath" />
                      {CustomPost.wc}
                    </span>
                  </div>
                )
              }
              
            })()}
            {(() => {
              if (CustomPost.wc != null) {
                return (
                  <div>
                    <span>
                      <img src={area} alt="area" />
                      {CustomPost.acreage}
                    </span>
                  </div>
                )
              }
              
            })()}
          </div>
        </div>
      </article>
    </div>
  )
}

export default PostEntry
