import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import PostEntry from "../components/PostEntry"
import Seo from "../components/Seo"

const CategoryTemplate = ({data}) => {
  const category = data.wpCategory
  const posts = data?.wpCategory?.posts?.nodes
  return (
    <Layout>
      <Seo
        title={category.name}
        description={category.name  + " Môi giới nhà đất chuyên nghệp Alphaland"}
        keywords={category.name  + " Nhà đất tp hcm, dự án bất động sản, chung cư tphcm, nhà đất Bình Dương, Nhà đất Đồng Nai"}
              seoImage={'https://www.alphaholdings.vn/ogimage-default.jpg'}
      />
      <div className="big-title mb-5">
        <div className="container text-center">
          <h1>{category.name}</h1>
        </div>
      </div>

      <div className="category">
        <div className="container">
          <div className="list-post w-100">
            <div className="row">
              {posts && posts.map(post =>
                <PostEntry key={post.id} post={post} />)}
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CategoryTemplate

export const pageQuery = graphql`
  query GET_CATEGORY($id: String!) {
    wpCategory(id: {eq: $id}) {
      id
      name
      slug
      posts {
        nodes {
          id
          title
          link
          uri
          excerpt
          CustomPost {
            acreage
            address
            bedroom
            fieldGroupName
            investor
            location
            phone
            price
            wc
          }
          featuredImage {
            node {
              altText
              sourceUrl
              localFile {
                childImageSharp {
                  gatsbyImageData(quality: 80, height: 600, width: 1000, placeholder: TRACED_SVG)
                }
              }
            }
          }
          news_papers {
            fieldGroupName
            newsPapers {
              excerpt
              link
              title
            }
          }
        }
      }
    }
  }
`
